import useFunnelStore from "~/stores/funnel";

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return

  const store = useFunnelStore()

  if (store.currentCatIndex !== 1) {
    return navigateTo('/funnel/next-cat');
  }
})